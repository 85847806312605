.set-your-time-container {
  .time-select-custom-container {
    width: 155px;
    border: 1px solid #4d5053;
    border-radius: 27px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-custom-time {
      &.dropdown {
        .btn {
          color: #ffffff;
          background-color: transparent;
          border: none;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          .selected-time {
            cursor: pointer;
          }
        }
        .dropdown-toggle.btn::after {
          display: none;
        }

        .dropdown-menu {
          transform: translate(-2px, 42px) !important;
          width: 155px;
          background-color: #1b1c1d;
          border: 1px solid #4d5053;
          border-radius: 25px;
          color: rgba(255, 255, 255, 0.5);
          padding: 10px 20px;
          left: -20px !important;
          .time-switcher-component {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .selector-item {
              text-align: center;
              .control-arrows {
                cursor: pointer;
                user-select: none; /* standard syntax */
                -webkit-user-select: none; /* webkit (safari, chrome) browsers */
                -moz-user-select: none; /* mozilla browsers */
                -khtml-user-select: none; /* webkit (konqueror) browsers */
                -ms-user-select: none; /* IE10+ */
                i {
                  font-size: 12px;
                  color: #fff;
                  opacity: 1;
                }
              }
              .control-arrows:hover {
                opacity: 0.9;
              }
              .secondary-number {
                cursor: pointer;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.5);
                padding: 10px 0;
              }
              .primary-number {
                font-size: 14px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .select-datetime {
      span:first-of-type {
        margin-right: 5px;
      }
      span {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.3);
        &.active {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}

.single-pagination-component{
    .pagination{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .page-item{
            display: flex;
            align-items: center;
            .page-link{
                display: flex;
                align-items: center;
                background-color: transparent;
                border: unset;
                .fa-circle{
                    color: #27282a;
                    font-size: 8px;
                }
            }

            .page-link:focus{
                box-shadow: unset;
            }

            &.active{
                .page-link{
                    .fa-circle{
                        color: #ed2626;
                        font-size: 14px;
                    }
                }
            }

            &.prev-button, &.next-button{
                margin: 0 25px;
                &.disabled{
                    .page-link{
                        color: rgba(255,255,255,0.2);
                    }
                }
                .page-link{
                    outline: unset;
                    border: unset;
                    box-shadow: unset;
                    border-radius: 6px;
                    background-color: #27282a;
                    color: #fff;
                }
            }
        }
    }
}
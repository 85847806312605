@font-face {
  font-family: 'SFProText';
  src: local('SFProText'), url('./assets/fonts/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
}
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .moveable-control-box {
    z-index: 1000;
  }
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
